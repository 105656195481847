export const EVENTS_DB = [
  {
    id: 1,
    day: "Monday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: "Fundamental Jiu Jitsu" },
      { time: "12:00", event: "Advanced Jiu Jitsu" },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null},
      { time: "19:00", event: "Self Development" },
      { time: "20:00", event: "Jiu Jitsu All Level" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors : {
      
      "11:00":" #A3DEFF",
      "19:00": "#FFCA88",
      "12:00": "#FFDABE",
      "20:00": "#EBD31F",

    }
  },
  {
    id: 2,
    day: "Tuesday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: "" },
      { time: "10:00", event: null },
      { time: "11:00", event: "Advanced Jiu Jitsu" },
      { time: "12:00", event: "Fundamental Jiu Jitsu" },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: "Teen Grapplers (12-15yrs)G1" },
      { time: "19:00", event: "NOGI All Levels" },
      { time: "20:00", event: "Female Jiu Jitsu Only" },
      { time: "21:00", event: "" },
      { time: "22:00", event: null },
    ],
    colors : {
    
      "11:00": "#FFDABE",
      "18:00": "#D1BEFA", 
      "19:00": "#CBE1B9",
      "12:00": "#A3DEFF" ,
      "20:00": "#FD469E" ,
    }
  },
  {
    id: 3,
    day: "Wednesday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: "Fundamental Jiu Jitsu" },
      { time: "12:00", event: "NOGI All Levels" },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null },
      { time: "19:00", event: "Fundamental Jiu Jitsu" },
      { time: "20:00", event: "Advanced Jiu Jitsu" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ], 
    colors :{
    "11:00":" #A3DEFF",
    "12:00": "#CBE1B9",
    "19:00": "#A3DEFF",
    "20:00": "#FFDABE",
  }
  },
  {
    id: 4,
    day: "Thursday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: "Advanced Jiu Jitsu" },
      { time: "12:00", event: "Fundamental Jiu Jitsu" },
      { time: "13:00", event: "" },
      { time: "14:00", event: "" },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null},
      { time: "19:00", event: "Jiu Jitsu All Level" },
      { time: "20:00", event: "Female Jiu Jitsu Only" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors : {
      "11:00": "#FFDABE",
      "12:00": "#A3DEFF" ,
      "19:00": "#EBD31F",
      "20:00": "#FD469E" ,
    }
  },
  {
    id: 5,
    day: "Friday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: "Fundamental Jiu Jitsu" },
      { time: "12:00", event: "Advanced Jiu Jitsu" },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null },
      { time: "19:00", event: "Fundamental Jiu Jitsu" },
      { time: "20:00", event: "NOGI All Levels" },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors : {
    
      "11:00":" #A3DEFF",
      "19:00": "#A3DEFF",
      "12:00": "#FFDABE",
      "20:00": "#CBE1B9",
    }
  },
  {
    id: 6,
    day: "Saturday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: "Fundamental Jiu Jitsu"},
      { time: "12:00", event: "Teen Grapplers (12-15yrs)G1 "},
      { time: "13:00", event: "Jiu Jitsu All Level"
    },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null },
      { time: "19:00", event: null },
      { time: "20:00", event: null },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors : {
    
      "11:00":" #A3DEFF",
      "12:00": "#D1BEFA", 
      "13:00": "#EBD31F", 

    }
  },
  {
    id: 7,
    day: "Sunday",
    events: [
      { time: "08:00", event: null },
      { time: "09:00", event: null },
      { time: "10:00", event: null },
      { time: "11:00", event: null },
      { time: "12:00", event: null },
      { time: "13:00", event: null },
      { time: "14:00", event: null },
      { time: "15:00", event: null },
      { time: "16:00", event: null },
      { time: "17:00", event: null },
      { time: "18:00", event: null },
      { time: "19:00", event: null },
      { time: "20:00", event: null },
      { time: "21:00", event: null },
      { time: "22:00", event: null },
    ],
    colors : {
    
      "8:00":" #F4F0F0",
      "9:00":" #F4F0F0",
      "10:00":" #F4F0F0",
      "11:00":" #F4F0F0",
      "12:00":" #F4F0F0",
      "13:00":" #F4F0F0",
      "14:00":" #F4F0F0",
      "15:00":" #F4F0F0",
      "16:00":" #F4F0F0",
      "17:00":" #F4F0F0",
      "18:00":" #F4F0F0",
      "19:00":" #F4F0F0",
      "20:00":" #F4F0F0",
      "21:00":" #F4F0F0",
      "22:00":" #F4F0F0",
      
    }
  },
];
